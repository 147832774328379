@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
  background: rgb(3, 3, 3);
  background: linear-gradient(
    90deg,
    rgba(3, 3, 3, 1) 0%,
    rgba(36, 29, 42, 1) 35%,
    rgba(19, 80, 93, 1) 100%
  );
}

ul.topnav li {
  /* float: left; */
}

ul.topnav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
}

ul.topnav li a:hover:not(.active) {
  background-color: #111;
}

ul.topnav li a.active {
  background: rgb(20, 13, 142);
  background: linear-gradient(
    90deg,
    rgba(20, 13, 142, 1) 0%,
    rgba(21, 21, 182, 1) 35%,
    rgba(16, 132, 156, 1) 100%
  );
}

ul.topnav li.right {
  float: right;
}

@media screen and (max-width: 700px) {
  ul.topnav {
    flex-direction: column;
  }

  .hide {
    display: none !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

body {
  font-family: georgia, serif;
}

h1.heading {
  background: linear-gradient(
    90deg,
    rgba(11, 5, 121, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.input-text,
select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.button-submit {
  width: 100%;
  background: rgb(20, 13, 142);
  background: linear-gradient(
    90deg,
    rgba(20, 13, 142, 1) 0%,
    rgba(21, 21, 182, 1) 35%,
    rgba(16, 132, 156, 1) 100%
  );
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-submit:hover {
  background: rgb(20, 13, 142);
  background: linear-gradient(
    90deg,
    rgba(20, 13, 142, 1) 0%,
    rgba(113, 21, 182, 1) 35%,
    rgba(16, 132, 156, 1) 100%
  );
}

