ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: center;
  background: rgb(3, 3, 3);
  background: linear-gradient(
    90deg,
    rgba(3, 3, 3, 1) 0%,
    rgba(36, 29, 42, 1) 35%,
    rgba(19, 80, 93, 1) 100%
  );
}

ul.topnav li {
  /* float: left; */
}

ul.topnav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
}

ul.topnav li a:hover:not(.active) {
  background-color: #111;
}

ul.topnav li a.active {
  background: rgb(20, 13, 142);
  background: linear-gradient(
    90deg,
    rgba(20, 13, 142, 1) 0%,
    rgba(21, 21, 182, 1) 35%,
    rgba(16, 132, 156, 1) 100%
  );
}

ul.topnav li.right {
  float: right;
}

@media screen and (max-width: 700px) {
  ul.topnav {
    flex-direction: column;
  }

  .hide {
    display: none !important;
  }
}
